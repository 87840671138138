import lab from './lab';
import qa from './qa';
import staging from './staging';
import production from './production';
import dev1 from './dev1';
import dev2 from './dev2';
import dev3 from './dev3';
import dev4 from './dev4';
import dev5 from './dev5';
import dev6 from './dev6';
import qa1 from './qa1';
import qa2 from './qa2';
import qa3 from './qa3';
import qa4 from './qa4';
import qa5 from './qa5';
import qa6 from './qa6';
import preprod1 from './preprod1';
import auto1 from './auto1';

const credentials = {
	lab,
	qa,
	staging,
	production,
	dev1,
	dev2,
	dev3,
	dev4,
	dev5,
	dev6,
	preprod1,
	qa1,
	qa2,
	qa3,
	qa4,
	qa5,
	qa6,
	auto1
};

export default credentials[process.env.PLANITY_ENV];
