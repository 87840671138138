import React, { useEffect } from 'react';
import credentials from '@planity/credentials';
import { invokeLambda } from '@planity/helpers';
import { SignUpWithGoogle } from './sign_up_with_google';
import { SignUpForm } from './sign_up_form';
import { SignUpWithMergeEmailAndPasswordWithProvider } from './sign_up_with_merge_email_and_password_with_provider';
import { useFormHooks } from '../useFormHooks';

export const SignUp = () => {
	useEffect(() => {
		if (process.env.BROWSER) {
			invokeLambda('collectHeaders', {
				input: JSON.stringify({ checkOnly: true })
			}).then(res => {
				if (res?.ban) {
					const urlRoot =
						process.env.NODE_ENV === 'production'
							? `https://www.${credentials.HOST}`
							: `http://localhost:3000`;
					window.location.href = `${urlRoot}/crash`;
				}
			});
		}
	}, []);
	const { formValues } = useFormHooks();
	const { machineState } = formValues;

	switch (machineState) {
		case 'askingUserToConnectWithGoogle':
		case 'signingInWithGoogleAndMergeAccount':
			return <SignUpWithGoogle />;

		case 'askingUserPassword':
		case 'checkingPasswordInput':
		case 'getCredentials':
		case 'mergingEmailAndPasswordWithProvider':
			return <SignUpWithMergeEmailAndPasswordWithProvider />;

		case 'checkingUserData':
		case 'checkingSignUpWithEmailAndPasswordInputs':
		case 'checkingProData':
		case 'fetchingDifferentAccounts':
		case 'idle':
		case 'transient':
		case 'success':
		case 'signingInWithEmailAndPassword':
		case 'signingInWithFacebook':
		case 'signingInWithGithub':
		case 'signingInWithGoogle':
		case 'signingUpWithEmailAndPassword':
		case 'checkOtherProviders':
		case 'settingUserPhoneInDatabase':
			return <SignUpForm />;

		default:
			console.warn(`Unhandled state view for ${machineState}`);
			return null;
	}
};
