import React, { Component, createRef } from 'react';
import { firebase } from '@planity/datastores';
import {
	businessService,
	businessServiceWebSelectableCalendars,
	businessWebVisibleServicesById,
	calendarsFromFormula,
	canChooseServiceSubsteps,
	computeFees,
	formatPhoneNumber,
	formatPrice,
	getFinalFees,
	getStepInfo,
	invokeLambda,
	IS_DEPOSIT,
	moveAppointmentWithWaitingList,
	moveAppointmentNoMailWithWaitingList,
	parseFormula,
	parseServices,
	safeRead,
	scrollTo,
	totalAppointmentPrice,
	UseHotJar,
	userCanDisplayConfirm,
	WHITELISTED_ERRORS_STRIPE,
	sendGoogleConversion,
	PAYMENT_METHOD
} from '@planity/helpers';
import {
	compareAsc,
	endOfMonth,
	format,
	parseISO,
	isAfter,
	startOfDay,
	isEqual as dateFnsIsEqual
} from 'date-fns';
import { withRouter } from 'react-router-dom';
import { withTranslation } from '@planity/localization';
import produce from 'immer';
import credentials from '@planity/credentials';
import { ErrorMessage } from '../error_message';
import AppointmentSteps from './steps';
import AppointmentDate from './date';
import { AppointmentUser } from './user';
import { AppointmentPayment } from '../business_booking/online_payment';
import AppointmentComment from './comment';
import OnLeaveWarning from './on_leave_warning';
import * as events from './events';
import {
	USER_ADD_COMMENT,
	USER_CHOSE_PAST_APPOINTMENT,
	USER_CHOSE_STEP_SERVICE,
	USER_CHOSE_DATE
} from './events';
import { OnlinePaymentConsumer } from '../business_booking/online_payment/providerComponent';
import { withFormFactor } from '../provider';
import { withStripeFees } from '../app_settings/stripeFeesProvider';
import { TEMPLATES_MODAL, withAppBanner } from '@planity/context';
import { About, Spinner, withModal, BusinessPopup } from '@planity/ui';
import { Confirmation } from './confirmation';
import { isNativeApp, sendToUserApp } from '@planity/webview';
import {
	withStripeElementsConsumer,
	FirebaseSubscription,
	WaitingPage,
	withViewerHeaders,
	MISSING_PHONE_NUMBER_ERROR
} from '@planity/components';
import isEqual from 'lodash/isEqual';
import {
	BOOK_APPOINTMENT_BLOC,
	withTheme
} from '@planity/context/theme_context';
import { breakpoints } from '@planity/theme';
import { withGoalEvents } from '@planity/helpers/analytics';
const { USE_CURE_FOR_PAYMENTS_ENABLED } = credentials;
const UNSKILLED_CALENDAR_ERROR = 'UNSKILLED_CALENDAR_ERROR';
const UNKNOWN_CALENDAR_ERROR = 'UNKNOWN_CALENDAR_ERROR';
const UNAVAILABLE_SEQUENCE_ERROR = 'UNAVAILABLE_SEQUENCE_ERROR';
const INVALID_START_ERROR = 'INVALID_START_ERROR';
const UNKNOWN_SERVICE_ERROR = 'UNKNOWN_SERVICE_ERROR';
const BLACKLISTED_USER_ERROR = 'BLACKLISTED_USER_ERROR';
const NETWORK_ERROR = 'NETWORK_ERROR';
const CANCELLATION_ERROR = 'CANCELLATION_ERROR';
const INACTIVE_BUSINESS_ERROR = 'INACTIVE_BUSINESS_ERROR';
const STRIPE_CARD_ERROR = 'STRIPE_CARD_ERROR';
const ERROR_PAYMENT_STATUS = 'ERROR_PAYMENT_STATUS';
const GENERIC_ERROR = 'GENERIC_ERROR';

const BLACKLISTED_USER_ERROR_WITH_PHONE = `${BLACKLISTED_USER_ERROR}.withPhone`;
const BLACKLISTED_USER_ERROR_WITHOUT_PHONE = `${BLACKLISTED_USER_ERROR}.withoutPhone`;

const MISSING_PHONE_NUMBER_ERROR_MESSAGE = `auth.errors.${MISSING_PHONE_NUMBER_ERROR}`;

const WHITELISTED_ERRORS = [
	UNSKILLED_CALENDAR_ERROR,
	UNKNOWN_CALENDAR_ERROR,
	UNAVAILABLE_SEQUENCE_ERROR,
	INVALID_START_ERROR,
	UNKNOWN_SERVICE_ERROR,
	BLACKLISTED_USER_ERROR_WITH_PHONE,
	BLACKLISTED_USER_ERROR_WITHOUT_PHONE,
	NETWORK_ERROR,
	CANCELLATION_ERROR,
	INACTIVE_BUSINESS_ERROR,
	STRIPE_CARD_ERROR,
	ERROR_PAYMENT_STATUS,
	GENERIC_ERROR
]
	.map(error => `bookAppointment.errors.${error}`)
	.concat(WHITELISTED_ERRORS_STRIPE, [MISSING_PHONE_NUMBER_ERROR_MESSAGE]);

const CURE_INITIAL_STATE = {
	eligibleCures: [],
	serviceIdsWithEligibleCures: [],
	allServicesAreCoveredByCures: false
};

class BookAppointmentComponent extends Component {
	hasScrolledToDate = false;
	hasScrolledToPayment = false;
	constructor() {
		super();
		this.paymentSection = createRef();
		this.state = {
			giftVoucherPaymentInfo: {
				giftVoucherNumber: null,
				amount: null,
				isValidated: false,
				error: null,
				expires: null
			},
			showGiftVoucherPaymentInfoErrorMessage: false,
			isBooking: false,
			cure: CURE_INITIAL_STATE,
			onlinePaymentServices: {
				isOnline: [],
				remaining: []
			},
			appointment: {
				steps: [{ offset: 0, serviceId: null }],
				date: null,
				hasConfirmableUser: false,
				lastSignUpId: null,
				commentToBusiness: ''
			},
			isPending: false,
			error: null,
			errorIsOnlinePayment: false,
			wasBooked: false,
			paymentMethod: PAYMENT_METHOD.PAY_DEPOSIT,
			isRedirectRestartingBooking: false,
			paymentCreatedDateToDelete: null,
			totalPriceToDelete: null,
			isPrePaymentToDelete: null,
			hasTokenStatus: null,
			businessInfoWasSeen: false,
			isCalendarReady: false,
			selectedUser: null
		};
	}

	appointmentDate = createRef();

	appointmentUser = createRef();

	componentDidMount() {
		const { locale, location, user, userId, business } = this.props;
		if (this.props.initialServiceId) {
			/**
			 * verify that the service is valid before selecting the service (!deleted, !forbidden, !hidden)
			 * this is required to select valid service from google redirection
			 */
			const validService = businessService(
				business,
				this.props.initialServiceId
			);
			validService &&
				this.dispatch(USER_CHOSE_STEP_SERVICE, {
					serviceId: this.props.initialServiceId
				});
		}

		if (location?.state?.sequence) {
			this.dispatch(USER_CHOSE_PAST_APPOINTMENT, {
				sequence: location.state.sequence
			});
		}

		if (location?.state?.date) {
			this.dispatch(USER_CHOSE_DATE, {
				date: location.state.date
			});
		}

		if (location?.state?.commentToBusiness) {
			this.dispatch(USER_ADD_COMMENT, {
				commentToBusiness: location.state.commentToBusiness
			});
		}

		const paymentCreatedAtToDelete =
			location?.state?.veventToDelete?.paymentCreatedAt || null;
		const formattedDate = paymentCreatedAtToDelete
			? new Intl.DateTimeFormat(locale).format(paymentCreatedAtToDelete * 1000)
			: null;

		const totalPriceToDelete =
			formatPrice(location?.state?.veventToDelete?.totalPrice, true) || null;

		this.setState(
			{
				user: {
					...user,
					email: firebase.auth().currentUser
						? firebase.auth().currentUser.email
						: null
				},
				paymentMethod:
					location?.state?.isDeposit === 'full' ||
					!!location?.state?.veventToDelete?.isPrePayment
						? PAYMENT_METHOD.PAY_ALL
						: PAYMENT_METHOD.PAY_DEPOSIT,
				isRedirect: !!location?.state?.isRedirect,
				error: location?.state?.paymentError,
				errorIsOnlinePayment: !!location?.state?.paymentError,
				paymentCreatedDateToDelete: formattedDate,
				isPrePaymentToDelete: !!location?.state?.veventToDelete?.isPrePayment,
				totalPriceToDelete,
				hasTokenStatus: !!location?.state?.hasTokenStatus,
				paymentMethodIdToDelete:
					location?.state?.veventToDelete?.paymentMethodId,
				veventToDelete: location?.state?.veventToDelete
			},
			() => {
				if (
					business.modules &&
					business.modules.onlinePayment &&
					business.settings &&
					business.settings.onlinePayment &&
					userId
				) {
					this.props.getCustomerStripe({
						userId,
						user: this.state.user,
						paymentMethod: location?.state?.paymentMethod || null,
						countryCode: business.countryCode
					});
				}
			}
		);
	}

	componentDidUpdate(prevProps, prevState) {
		const { userId } = prevProps;
		const {
			business,
			businessId,
			location,
			resetPaymentIntent,
			intentID,
			updatePaymentIntent,
			paymentMethodType,
			allStripeFees,
			t,
			setModal,
			closeModal,
			status,
			isGiftVoucher,
			userCures
		} = this.props;

		const {
			error,
			errorIsOnlinePayment,
			appointment,
			paymentMethod,
			selectedUser,
			onlinePaymentServices
		} = this.state;

		// If we move an appointment with a gift card, we check if it is still valid
		const isMovingAppointment = !!location?.state?.veventToDelete;
		const giftVouchersBookingMethods =
			isMovingAppointment && location?.state?.veventToDelete?.bm?.giftVouchers
				? Object.keys(location.state.veventToDelete.bm.giftVouchers)
				: [];
		const shouldValidateGiftVoucherForMovingAppointment =
			isMovingAppointment && giftVouchersBookingMethods.length > 0;
		const hasDateChanged =
			appointment.date && prevState.appointment.date !== appointment.date;
		if (hasDateChanged) {
			this.setState({
				showGiftVoucherPaymentInfoErrorMessage: false
			});
		}
		const hasUserChanged =
			selectedUser && prevState.selectedUser !== selectedUser;
		if (
			shouldValidateGiftVoucherForMovingAppointment &&
			(hasDateChanged || hasUserChanged)
		) {
			this.validateGiftVoucherForMoveAppointment(
				giftVouchersBookingMethods,
				businessId,
				appointment.date
			).then(response => {
				this.props.setSelectedPaymentMethod(null);
				this.setState({
					giftVoucherPaymentInfo: {
						giftVoucherNumber: giftVouchersBookingMethods[0],
						isValidated: response.isValid,
						expires: response.expires,
						amount: response.amount,
						error: response.error
					},
					paymentMethod: this.determinePaymentMethod(response),
					showGiftVoucherPaymentInfoErrorMessage: !!response.error
				});
			});
		}

		const isPrePayment =
			location?.state?.isPrePayment ||
			business?.settings?.onlinePayment?.prePayment;
		const { ENABLE_USER_PAYS_FEES } = credentials;
		const isDeposit = status === IS_DEPOSIT;
		const userPaysFees =
			ENABLE_USER_PAYS_FEES &&
			!!business?.settings?.onlinePayment?.userPaysFees;

		if (USE_CURE_FOR_PAYMENTS_ENABLED) {
			const {
				allServicesAreCoveredByCures,
				eligibleCures,
				serviceIdsWithEligibleCures
			} = this.getEligibleCuresForOnlinePaymentServices({
				location,
				business,
				businessId,
				userCures,
				appointment,
				selectedUser,
				onlinePaymentServices
			});

			const isMovingAppointmentBookedWithoutCure =
				isMovingAppointment && !location?.state?.veventToDelete?.bm?.cures;
			const prevEligibleCure = prevState.cure
				? prevState.cure.eligibleCures
				: [];

			if (
				!isMovingAppointmentBookedWithoutCure &&
				eligibleCures.length > 0 &&
				!isEqual(prevEligibleCure, eligibleCures) &&
				!shouldValidateGiftVoucherForMovingAppointment
			) {
				this.setState({
					cure: {
						allServicesAreCoveredByCures,
						eligibleCures,
						serviceIdsWithEligibleCures
					},
					paymentMethod: PAYMENT_METHOD.USE_CURE
				});
			}

			// Reset initial state if selected service has no eligible cures
			if (
				eligibleCures.length === 0 &&
				serviceIdsWithEligibleCures.length === 0 &&
				this.state.paymentMethod === PAYMENT_METHOD.USE_CURE
			) {
				this.setState({
					paymentMethod:
						location?.state?.isDeposit === 'full' ||
						!!location?.state?.veventToDelete?.isPrePayment
							? PAYMENT_METHOD.PAY_ALL
							: PAYMENT_METHOD.PAY_DEPOSIT,
					cure: CURE_INITIAL_STATE
				});
			}
		}

		const stripeFees = getFinalFees(
			allStripeFees,
			business?.countryCode,
			paymentMethodType
		);
		const previousStripeFees = getFinalFees(
			allStripeFees,
			business?.countryCode,
			prevProps.paymentMethodType
		);

		const hasPaymentMethodStripeFeesChanged = !isEqual(
			stripeFees,
			previousStripeFees
		);

		const { totalPriceWithoutFees, depositPriceWithoutFees } =
			totalAppointmentPrice(
				this.state.onlinePaymentServices,
				userPaysFees,
				stripeFees
			);

		const price =
			isDeposit && paymentMethod === PAYMENT_METHOD.PAY_DEPOSIT
				? depositPriceWithoutFees
				: totalPriceWithoutFees;
		const application_fee_amount =
			price > 0
				? computeFees({
						amount: price,
						stripeFees
				  })
				: 0;

		const amount = Math.round(
			(isDeposit && paymentMethod === PAYMENT_METHOD.PAY_DEPOSIT
				? depositPriceWithoutFees
				: totalPriceWithoutFees) + (userPaysFees ? application_fee_amount : 0)
		);

		// Resets the error state because the component's setError does not handle it
		if (error && errorIsOnlinePayment) {
			this.setState({ error: null });
		}

		// if appointment change of steps or date or deposit payAll or payment method fees (card/klarna/bancontact)
		// payment intent needs to be updated (and if steps changed, then date change because it has to be selected again so condition is ok)
		const hasAppointmentOrPaymentMethodFeesChanged =
			appointment?.hasConfirmableUser &&
			prevState.appointment &&
			appointment?.date &&
			(!isEqual(prevState.appointment, appointment) ||
				hasPaymentMethodStripeFeesChanged);
		if (
			intentID &&
			isPrePayment &&
			amount > 0 &&
			(hasAppointmentOrPaymentMethodFeesChanged ||
				(isDeposit && prevState.paymentMethod !== paymentMethod))
		) {
			const amountWithoutFees = Math.trunc(
				isDeposit && paymentMethod === PAYMENT_METHOD.PAY_DEPOSIT
					? depositPriceWithoutFees
					: totalPriceWithoutFees
			);

			const { childId, childName } = getChildInfos(selectedUser);
			const onlinePaymentServices = this.getOnlinePaymentServices();
			const metadata = {
				amountWithoutFees,
				childId,
				childName,
				date: appointment?.date
					? format(appointment.date, 'yyyy-MM-dd HH:mm')
					: null,
				totalAmount: Math.round(totalPriceWithoutFees),
				formattedSteps: JSON.stringify(onlinePaymentServices),
				transactionType: isDeposit
					? paymentMethod === PAYMENT_METHOD.PAY_ALL
						? 'fullDeposit'
						: 'partialDeposit'
					: isPrePayment
					? 'prepayment'
					: 'classicOnlinePayment'
			};

			updatePaymentIntent({
				intentID,
				amount,
				application_fee_amount,
				metadata,
				countryCode: business?.countryCode,
				businessId
			});
		}

		//resets the error state because the component's setError does not handle it
		error && errorIsOnlinePayment && this.setState({ error: null });
		// after reset, handle the action due to the error: reset all payment info
		if (error && errorIsOnlinePayment) {
			resetPaymentIntent();
		}

		if (
			typeof this.props.userId === 'undefined' &&
			this.props.userId !== userId
		) {
			this.props.resetPaymentMethodDatas();
		} else if (this.props.userId && this.props.userId !== userId) {
			if (
				prevProps.business.modules &&
				prevProps.business.modules.onlinePayment &&
				prevProps.business.settings &&
				prevProps.business.settings.onlinePayment &&
				this.props.userId !== userId
			) {
				this.props.getCustomerStripe({
					userId: this.props.userId,
					user: this.state.user,
					countryCode: prevProps.business.countryCode
				});
			}
		}

		if (this.props.initialServiceId !== prevProps.initialServiceId) {
			const serviceId = this.props.initialServiceId;
			if (serviceId) {
				const validService = businessService(business, serviceId);
				validService && this.dispatch(USER_CHOSE_STEP_SERVICE, { serviceId });
			}
		}

		const canDisplayConfirm = userCanDisplayConfirm(this.state.appointment);
		const hasStartedBooking = !!this.state.appointment.steps.find(
			step => !!step.serviceId
		);

		// Scroll to AppointmentDate when coming from waiting list email or move an appointment
		if (
			this.state.veventToDelete &&
			this.appointmentDate &&
			this.state.user &&
			hasStartedBooking &&
			this.state.isCalendarReady &&
			!this.hasScrolledToDate
		) {
			scrollTo({ node: this.appointmentDate.current, animated: true });
			this.hasScrolledToDate = true;
		}

		if (
			(this.props.formFactor === 'phone' && !canDisplayConfirm) ||
			(process.env.WIDGET && !process.env.WHITE_LABEL_WEBSITE)
		) {
			if (this.state.appointment.date && !prevState.appointment.date) {
				if (this.appointmentDate) {
					scrollTo({ node: this.appointmentDate.current, animated: true });
				}
			}
		}

		// Scroll to payment section
		if (
			!this.hasScrolledToPayment &&
			!this.state.wasBooked &&
			canDisplayConfirm
		) {
			if (!process.env.WIDGET && process.env.BROWSER) {
				scrollTo({
					node: this.paymentSection.current,
					offset: 150,
					animated: true
				});
				this.hasScrolledToPayment = true;
			}
		}

		if (
			appointment?.date &&
			business?.covidMessage &&
			business?.covidMessage !== t('covid.defaultMessage') &&
			!this.state.businessInfoWasSeen &&
			!this.props.location?.state?.isRedirect
		) {
			this.setState({ businessInfoWasSeen: true });
			return setModal({
				content: (
					<BusinessPopup
						message={business.covidMessage}
						onSubmit={closeModal}
					/>
				),
				hasCloseBtn: false,
				disableScrollTop: process.env.WHITE_LABEL_WEBSITE
			});
		}
	}

	render() {
		const {
			userId,
			isVerified,
			user,
			location,
			onGiftButtonClick,
			giftButtonText,
			status,
			serviceSetsWhitelist,
			servicesWhitelist,
			servicesNotCollapsed,
			globalSearch,
			business,
			businessId,
			redirectTo,
			onBooking,
			hasDarkBackground,
			highlightedServices,
			hasPastAppointments,
			isPreview,
			userFilter,
			customerID,
			conditionsLink,
			warnOnLeave,
			isPaymentIntentUpdating,
			isAuthLoading,
			isIOSViewer,
			parentPlace,
			place,
			crumbs,
			userCures
		} = this.props;

		const { appointment, selectedUser, onlinePaymentServices } = this.state;

		const {
			allServicesAreCoveredByCures,
			eligibleCures,
			serviceIdsWithEligibleCures
		} = this.getEligibleCuresForOnlinePaymentServices({
			location,
			business,
			businessId,
			userCures,
			appointment,
			selectedUser,
			onlinePaymentServices
		});
		const cureUsedForAppointment = this.getAppointmentCure(location, userCures);
		const isCureExpirationValidForAppointment = cureUsedForAppointment?.expires
			? isAfter(cureUsedForAppointment.expires, this.state.appointment.date) ||
			  isEqual(cureUsedForAppointment.expires, this.state.appointment.date)
			: true;

		const isDark = hasDarkBackground?.[BOOK_APPOINTMENT_BLOC];
		const hideMobileTitles = !!(
			this.state.appointment.date &&
			userId &&
			isVerified
		);

		const computedTotalPrice = this.computeTotalPrice(
			this.state.appointment?.steps,
			business
		);

		const totalPrice = computedTotalPrice
			? formatPrice(computedTotalPrice, true)
			: null;

		const sectionProps = {
			appointment: this.state.appointment,
			isPending: this.state.isPending,
			business,
			businessId,
			dispatch: this.dispatch,
			wasBooked: this.state.wasBooked,
			book: this.book,
			hideMobileTitles
		};
		const hasStartedBooking = !!this.state.appointment.steps.find(
			step => !!step.serviceId
		);
		const moveAppointment = !!location?.state?.veventToDelete;

		const canDisplayConfirmOnBooking = userCanDisplayConfirm(
			sectionProps.appointment
		);

		const isPrePayment = business?.settings?.onlinePayment?.prePayment;
		const isVisiblePayment =
			!!business?.modules?.onlinePayment &&
			!!business?.settings?.onlinePayment &&
			this.state.onlinePaymentServices.isOnline.length > 0;

		const { childId, childName } = getChildInfos(this.state.selectedUser);

		if (
			!!location?.state &&
			!!location?.state?.isRedirect &&
			!location?.state?.error &&
			!this.state.hasTokenStatus &&
			(!this.props.singlePageApp ||
				location?.state?.isFrom === BOOK_APPOINTMENT_BLOC)
		) {
			return (
				<FirebaseSubscription
					path={`${
						this.props.isPro ? 'pros' : 'users'
					}/${userId}/redirect_tokens/${location.state.tokenId}/response`}
				>
					{({ data: redirectLambdaResponse }) => {
						this.handleRedirectLambdaResponse(redirectLambdaResponse);
						return <WaitingPage />;
					}}
				</FirebaseSubscription>
			);
		}

		return (
			<div css={onBooking ? styles.bookAppointment : {}}>
				<ErrorMessage
					{...this.getError()}
					defaultMessage={'bookAppointment.errors.defaultError'}
					whitelist={WHITELISTED_ERRORS}
				/>
				<AppointmentSteps
					{...sectionProps}
					giftButtonText={giftButtonText}
					globalSearch={globalSearch}
					hasPastAppointments={hasPastAppointments}
					hasStartedBooking={hasStartedBooking}
					highlightedServices={highlightedServices}
					redirectTo={redirectTo}
					serviceSetsWhitelist={serviceSetsWhitelist}
					servicesNotCollapsed={servicesNotCollapsed}
					servicesWhitelist={servicesWhitelist}
					onBooking={onBooking || (process.env.WIDGET && hasStartedBooking)}
					onGiftButtonClick={onGiftButtonClick}
				/>
				{!hasStartedBooking && !process.env.WIDGET && (
					<About
						business={business}
						className={'desktop'}
						crumbs={crumbs}
						parentPlace={parentPlace}
						place={place}
					/>
				)}
				<UseHotJar
					feature={
						this.state.veventToDelete?.fromWaitingListMail && 'waitingListMail'
					}
				>
					<div ref={this.appointmentDate}>
						<AppointmentDate
							{...sectionProps}
							isPreview={isPreview}
							moveAppointment={moveAppointment}
							userFilter={userFilter}
							onCalendarReady={
								this.state.isCalendarReady
									? undefined
									: () => this.setState({ isCalendarReady: true })
							}
						/>
					</div>
				</UseHotJar>
				<div ref={this.appointmentUser}>
					<AppointmentUser
						{...sectionProps}
						bookedByToDelete={location?.state?.veventToDelete?.bookedBy}
						bookedForToDelete={location?.state?.veventToDelete?.bookedFor}
						conditionsLink={conditionsLink}
						isBookAppointment
						isDark={isDark}
						isOnline={this.state.onlinePaymentServices.isOnline.length > 0}
						isPrePaymentToDelete={this.state.isPrePaymentToDelete}
						moveAppointment={moveAppointment}
						paymentCreatedDateToDelete={this.state.paymentCreatedDateToDelete}
						phoneError={
							this.state.error &&
							this.state.error === MISSING_PHONE_NUMBER_ERROR_MESSAGE
						}
						selectedUser={this.state.selectedUser}
						setHasConfirmableUser={this.handleConfirmableUser}
						setSelectedUser={user => {
							this.setState({ selectedUser: user });
						}}
						totalPrice={totalPrice}
						totalPriceToDelete={this.state.totalPriceToDelete}
						userId={userId}
						onSignUp={lastSignUpId => {
							this.setState(({ appointment }) => ({
								appointment: {
									...appointment,
									lastSignUpId
								}
							}));
						}}
					/>
				</div>
				{sectionProps.appointment.date && userId && !isAuthLoading && (
					<UseHotJar
						feature={
							!!this.state.appointment.commentToBusiness && 'appointmentComment'
						}
					>
						<AppointmentComment
							{...sectionProps}
							onChange={commentToBusiness => {
								this.dispatch(USER_ADD_COMMENT, {
									commentToBusiness
								});
							}}
						/>
					</UseHotJar>
				)}
				{isVisiblePayment && this.state.selectedUser && (
					<UseHotJar feature={'onlinePayment'}>
						{customerID ? (
							<div ref={this.paymentSection}>
								<AppointmentPayment
									{...sectionProps}
									{...this.props}
									allServicesAreCoveredByCures={allServicesAreCoveredByCures}
									canDisplayConfirmOnBooking={canDisplayConfirmOnBooking}
									childId={childId}
									childName={childName}
									confirm={this.handleAppointmentPayment}
									cureUsedForAppointment={cureUsedForAppointment}
									eligibleCures={eligibleCures}
									formatAppointmentForLambda={() =>
										formatAppointmentForLambda(
											this.state.appointment,
											this.props.business,
											this.props.singlePageApp,
											this.state.onlinePaymentServices.isOnline.concat(
												this.state.onlinePaymentServices.remaining
											),
											{ isIOSViewer }
										)
									}
									formattedSteps={this.getOnlinePaymentServices()}
									giftVoucherPaymentInfo={this.state.giftVoucherPaymentInfo}
									isCureExpirationValidForAppointment={
										isCureExpirationValidForAppointment
									}
									isDark={isDark}
									isLoading={isPaymentIntentUpdating || this.state.isBooking}
									isOnline={
										this.state.onlinePaymentServices.isOnline.length > 0
									}
									isPrepayment={isPrePayment}
									isPrePaymentToDelete={this.state.isPrePaymentToDelete}
									isProWithMissingPhone={this.isProWithMissingPhone}
									isRedirectRestartingBooking={
										this.state.isRedirectRestartingBooking
									}
									moveAppointment={moveAppointment}
									onlinePaymentServices={this.state.onlinePaymentServices}
									paymentCreatedDateToDelete={
										this.state.paymentCreatedDateToDelete
									}
									paymentMethod={this.state.paymentMethod}
									paymentMethodIdToDelete={this.state.paymentMethodIdToDelete}
									serviceIdsWithEligibleCures={serviceIdsWithEligibleCures}
									setError={error =>
										this.setState({ error, errorIsOnlinePayment: true })
									}
									setIsLoading={newIsLoading =>
										this.setState({ isBooking: newIsLoading })
									}
									setPaymentMethod={paymentMethod =>
										this.setState({ paymentMethod })
									}
									showGiftVoucherPaymentInfoErrorMessage={
										this.state.showGiftVoucherPaymentInfoErrorMessage
									}
									status={status}
									totalPriceToDelete={this.state.totalPriceToDelete}
									user={user}
									userId={userId}
								/>
							</div>
						) : (
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									margin: 24
								}}
							>
								<Spinner />
							</div>
						)}
					</UseHotJar>
				)}
				{!isVisiblePayment &&
					hasStartedBooking &&
					canDisplayConfirmOnBooking && (
						// if no online payment
						<div css={styles.confirmation} ref={this.paymentSection}>
							<Confirmation
								{...sectionProps}
								confirm={() => this.book({ userId })}
								isLoading={this.state.isBooking}
								isOnline={this.state.onlinePaymentServices.isOnline.length > 0}
								isPrePayment={isPrePayment}
								moveAppointment={moveAppointment}
								userId={userId}
							/>
						</div>
					)}
				{/* don't delete it, it is to make the blurp from payment element more esthetic, validated by Paul */}
				<div css={styles.divToMakeItBeautiful} />

				{!!warnOnLeave && hasStartedBooking && <OnLeaveWarning />}
			</div>
		);
	}
	determinePaymentMethod = response => {
		const { location, status, business } = this.props;
		const hasPos = business?.modules?.pos;
		const locationState = location?.state;
		const isFullDeposit = locationState?.isDeposit === 'full';
		const isPrePayment = !!locationState?.veventToDelete?.isPrePayment;

		if (
			response.isValid ||
			(!response.isValid && status === IS_DEPOSIT && hasPos)
		) {
			return PAYMENT_METHOD.USE_GIFT_VOUCHER;
		}

		if (isFullDeposit || isPrePayment) {
			return PAYMENT_METHOD.PAY_ALL;
		}

		return PAYMENT_METHOD.PAY_DEPOSIT;
	};

	getEligibleCuresForOnlinePaymentServices = ({
		location,
		business,
		businessId,
		userCures,
		appointment,
		selectedUser,
		onlinePaymentServices
	}) => {
		const moveAppointment = !!location?.state?.veventToDelete;
		if (
			(!business?.modules?.pos && !moveAppointment) ||
			selectedUser?.childId
		) {
			return {
				allServicesAreCoveredByCures: false,
				eligibleCures: [],
				serviceIdsWithEligibleCures: []
			};
		}
		const serviceIds = (onlinePaymentServices.isOnline ?? []).map(
			service => service.id
		);

		const notExpiredCures = Object.entries(userCures).reduce(
			(acc, [cureId, cure]) => {
				if (
					cure.businessId === businessId &&
					cure.quantity > 0 &&
					(!cure.expires ||
						isAfter(cure.expires, appointment?.date) ||
						dateFnsIsEqual(cure.expires, appointment?.date))
				) {
					acc[cureId] = cure;
				}
				return acc;
			},
			{}
		);

		const sortedCureIds = Object.keys(notExpiredCures).sort();

		const serviceIdsWithEligibleCures = new Set();
		const servicesWithTheirCure = [];
		serviceIds.forEach(serviceId => {
			const applicableCure = sortedCureIds.find(cureId => {
				const cure = userCures[cureId];
				const serviceIsEligible = Object.keys(cure.services ?? {}).includes(
					serviceId
				);
				return serviceIsEligible;
			});

			if (applicableCure) {
				serviceIdsWithEligibleCures.add(serviceId);
				servicesWithTheirCure.push({
					serviceId,
					cures: [notExpiredCures[applicableCure]]
				});
			}
		});

		const allServicesAreCoveredByCures = serviceIds.every(serviceId =>
			serviceIdsWithEligibleCures.has(serviceId)
		);

		return {
			allServicesAreCoveredByCures,
			eligibleCures: servicesWithTheirCure,
			serviceIdsWithEligibleCures: Array.from(serviceIdsWithEligibleCures)
		};
	};

	getAppointmentCure(location, userCures) {
		// Check if moveAppointment is true
		const moveAppointment = !!location?.state?.veventToDelete;

		// Retrieve the booking methods for cures
		const curesBookingMethods =
			moveAppointment && location?.state?.veventToDelete?.bm?.cures
				? location.state.veventToDelete.bm.cures
				: {};
		// Get the first key from the booking methods for cures
		const bmCure = Object.keys(curesBookingMethods)[0];

		// Access the corresponding cure from userCures
		const appointmentBookedCure = userCures[bmCure];

		return appointmentBookedCure;
	}
	async validateGiftVoucherForMoveAppointment(
		giftVouchersBookingMethods,
		businessId,
		date
	) {
		// Get the first key from the booking methods for giftVoucher
		const giftVoucherNumber = giftVouchersBookingMethods[0];
		const userToken = await firebase.auth().currentUser?.getIdToken();

		const response = await invokeLambda('validateGiftVoucherForPayment', {
			businessId: businessId,
			giftVoucherNumber: parseInt(giftVoucherNumber),
			appointmentStart: Date.parse(date),
			firebaseToken: userToken
		});
		return response;
	}
	handleRedirectLambdaResponse = response => {
		const { location, onSuccess } = this.props;
		const { status, data } = response || {};

		const unformattedData =
			data && status === 'succeed' ? unformatFirebaseResponse(data) : null;

		if (status === 'succeed' && unformattedData) {
			if (onSuccess) {
				onSuccess({
					...unformattedData,
					appointmentMoved: !!location.state.veventToDelete
				});
			} else {
				this.setState({
					isPending: false,
					isBooking: false,
					wasBooked: true,
					hasTokenStatus: true
				});
			}
		} else if (status === 'failed' && data) {
			const error =
				data.error?.code ||
				(data.error.name === 'TypeError' ? NETWORK_ERROR : data.error);
			this.setState(state => ({
				hasTokenStatus: true,
				isPending: false,
				isBooking: false,
				error,
				errorIsOnlinePayment: true,
				...this.handleError(state, error)
			}));
		}
	};

	getError = () => {
		const { error } = this.state;
		const { business } = this.props;
		if (!error) return null;
		switch (error) {
			case MISSING_PHONE_NUMBER_ERROR_MESSAGE:
				return {
					error: MISSING_PHONE_NUMBER_ERROR_MESSAGE,
					message: MISSING_PHONE_NUMBER_ERROR_MESSAGE
				};
			case BLACKLISTED_USER_ERROR:
				return {
					error: `bookAppointment.errors.${error}.${
						business.phoneNumber ? 'withPhone' : 'withoutPhone'
					}`,
					message: `bookAppointment.errors.${error}.${
						business.phoneNumber ? 'withPhone' : 'withoutPhone'
					}`,
					args: {
						phoneNumber: formatPhoneNumber(business.phoneNumber),
						rawPhoneNumber: business.phoneNumber
					}
				};
			default:
				return {
					error: this.isStripeErrorCode(error)
						? `stripe.errors.${error}`
						: `bookAppointment.errors.${error}`,
					message: this.isStripeErrorCode(error)
						? `stripe.errors.${error}`
						: `bookAppointment.errors.${error}`
				};
		}
	};

	isStripeErrorCode = error =>
		WHITELISTED_ERRORS_STRIPE.includes(`stripe.errors.${error}`);

	dispatch = (type, payload) => {
		const { business, onServiceAdd, customerID } = this.props;
		this.setState(
			({ appointment, error }) => {
				const data = {
					appointment: reduceBookAppointment(
						appointment,
						type,
						payload,
						business
					),
					error: this.cleanStaleError(error, type)
				};
				return data;
			},
			() => {
				if (type === events.USER_CHOSE_STEP_SERVICE) {
					if (onServiceAdd) {
						onServiceAdd();
					}
				}

				if (
					customerID &&
					safeRead(business, ['modules', 'onlinePayment']) &&
					safeRead(business, ['settings', 'onlinePayment'])
				) {
					this.setState({
						onlinePaymentServices: parseServices(
							this.state.appointment.steps,
							business
						)
					});
				}
			}
		);
	};

	computeTotalPrice = (steps, business) => {
		if (!steps) return null;
		return (
			Object.values(steps || {}).reduce((total, currentStep) => {
				const { price } = getStepInfo(currentStep, business, this.props.t);
				if (!price) return total;
				const parsedPrice =
					parseFloat(
						price.replace(',', '.').replace('€', '').replace('&euro;', '')
					) ||
					parseFloat(price.replace(/,/g, '.').match(/([\d.]+)/) && RegExp.$1);
				return price ? total + parsedPrice : total;
			}, 0) * 100
		);
	};

	book = ({
		userId,
		isBookedWithCure,
		giftVoucherNumber,
		paymentMethod,
		isPrePayment,
		paymentIntentId,
		hasSameAmount
	}) => {
		const { error, isPending, errorIsOnlinePayment, isRedirect } = this.state;
		const { location, business } = this.props;
		const { ENABLE_USER_PAYS_FEES } = credentials;
		const userPaysFees = !!(
			paymentMethod && business?.settings?.onlinePayment?.userPaysFees
		);

		if (
			!isPending &&
			(!error || error === NETWORK_ERROR || errorIsOnlinePayment) &&
			!this.isProWithMissingPhone()
		) {
			this.setState(
				{
					isPending: true,
					error: null,
					errorIsOnlinePayment: false,
					isBooking: true
				},
				() => {
					const currentUser = firebase.auth().currentUser;
					if (currentUser) {
						currentUser.getIdToken().then(userToken => {
							const veventToDelete =
								location.state && location.state.veventToDelete;

							try {
								if (isBookedWithCure) {
									this.callBookAppointment({
										userId,
										userToken,
										businessId: this.props.businessId,
										isBookedWithCure,
										veventToDelete
									});
								} else if (giftVoucherNumber) {
									this.callBookAppointment({
										userId,
										userToken,
										businessId: this.props.businessId,
										giftVoucherNumber,
										veventToDelete
									});
								} else {
									const { totalPriceWithoutFees, depositPriceWithoutFees } =
										this.getTotalAndDepositPrices();
									const onlinePaymentServices = this.getOnlinePaymentServices();
									const hasOnlinePaymentServices =
										this.state.onlinePaymentServices.isOnline.length > 0;

									let isDeposit = null;
									let amount = totalPriceWithoutFees;
									if (
										this.props.status === IS_DEPOSIT &&
										hasOnlinePaymentServices
									) {
										isDeposit = 'full';
										if (
											this.state.paymentMethod === PAYMENT_METHOD.PAY_DEPOSIT
										) {
											isDeposit = 'partial';
											amount = depositPriceWithoutFees;
										}
									}
									this.callBookAppointment({
										userId,
										paymentMethod,
										isPrePayment,
										userToken,
										veventToDelete,
										ENABLE_USER_PAYS_FEES,
										userPaysFees,
										amount,
										isDeposit,
										businessId: this.props.businessId,
										steps: JSON.stringify(onlinePaymentServices),
										customerName: this.state.user.name,
										paymentIntentId,
										hasSameAmount
									});
								}
							} catch (e) {
								this.setState({
									isPending: false,
									error: isRedirect ? null : STRIPE_CARD_ERROR,
									errorIsOnlinePayment: isRedirect,
									isBooking: false,
									isRedirectRestartingBooking: isRedirect
								});
							}
						});
					}
				}
			);
		}
	};

	callBookAppointment = ({
		userId,
		paymentMethod,
		isPrePayment,
		isDeposit,
		giftVoucherNumber,
		isBookedWithCure,
		userToken,
		veventToDelete,
		ENABLE_USER_PAYS_FEES,
		userPaysFees,
		chargeId,
		amount,
		paymentIntentId,
		steps,
		customerName,
		hasSameAmount
	}) => {
		const { childId } = this.state.selectedUser;
		const { isIOSViewer, displayAppBanner, appBannerCanBeDisplayed } =
			this.props;
		// In case of customer moving its prepaid appointment, we need to retrieve veventToDelete's last4 and paymentMethodId, if the appointment has the same amount,
		// because paymentMethod is empty if customer has used klarna, or deleted its card meanwhile.
		const paymentMethodId =
			hasSameAmount && veventToDelete?.paymentMethodId
				? veventToDelete?.paymentMethodId
				: paymentMethod
				? paymentMethod.id
				: null;

		const last4 =
			hasSameAmount && veventToDelete?.last4
				? veventToDelete?.last4
				: paymentMethod?.card?.last4 || null;

		invokeLambda('bookAppointment', {
			userId,
			childId,
			userToken,
			isPrePayment,
			isDeposit,
			giftVoucherNumber,
			isBookedWithCure,
			businessId: this.props.businessId,
			paymentMethod: paymentMethodId,
			last4,
			chargeId,
			appointment: formatAppointmentForLambda(
				this.state.appointment,
				this.props.business,
				this.props.singlePageApp,
				this.state.onlinePaymentServices.isOnline.concat(
					this.state.onlinePaymentServices.remaining
				),
				{ isIOSViewer }
			),
			veventToDelete,
			userPaysFees: ENABLE_USER_PAYS_FEES && userPaysFees ? true : null,
			flags: {
				HIDE_ESHOP: process.env.WIDGET ? true : credentials.HIDE_ESHOP
			},
			amount: amount ? Math.round(amount) : amount,
			paymentIntentId,
			steps,
			customerName,
			newPaymentFlow: true,
			userHasBeenThroughSearch: this.props.hasBeenThroughSearch,
			userHasBeenThroughLanding: this.props.hasBeenThroughLanding,
			userHasBeenThroughBusiness: this.props.hasBeenThroughBusiness,
			userHasBeenThroughBookingStep: this.props.hasBeenThroughBookingStep,
			hasBeenThroughUserSignup: this.props.hasBeenThroughUserSignup,
			userHasBeenThroughBookingSuccess: this.props.hasBeenThroughBookingSuccess
		})
			.then(response => {
				if (response && response.errorMessage) {
					throw response.errorMessage;
				}

				const rwgToken = JSON.parse(localStorage.getItem('rwgToken')); // { value, createdAt }
				// handle google conversion only for new appointment
				if (rwgToken?.value && !veventToDelete) {
					sendGoogleConversion(rwgToken);
					localStorage.removeItem('rwgToken');
				}

				if (this.props.onSuccess) {
					this.props.onSuccess({
						...response,
						appointmentMoved: !!veventToDelete
					});
				} else {
					this.setState({
						isPending: false,
						isBooking: false,
						wasBooked: true
					});
				}
				if (isNativeApp) {
					setTimeout(() => {
						sendToUserApp({ type: 'RATE_APP', payload: null });
					}, 3000);
				}
				if (appBannerCanBeDisplayed) {
					displayAppBanner(TEMPLATES_MODAL.BOOK_APPOINTMENT);
				}
				if (veventToDelete?.fromWaitingListMail) {
					// first check if moving from waiting list email
					moveAppointmentWithWaitingList();
				} else if (veventToDelete?.hasWaitingListActivated) {
					// then if it was move directly with mail but still with waiting list
					moveAppointmentNoMailWithWaitingList();
				}
			})
			.catch(e => {
				console.error(e);
				const error = e.code || (e.name === 'TypeError' ? NETWORK_ERROR : e);
				this.setState(state => ({
					isPending: false,
					isBooking: false,
					error,
					errorIsOnlinePayment: true,
					...this.handleError(state, error)
				}));
			});
	};

	getOnlinePaymentServices() {
		return this.state.onlinePaymentServices.isOnline
			.concat(this.state.onlinePaymentServices.remaining)
			.reduce((allServices, service) => {
				const { id, name, cancelRate, noshowRate, prices } = service;
				if (prices) {
					const { default: defaultPrice, min } = prices;
					allServices.push({
						serviceId: id,
						service: name,
						cancelRate,
						noshowRate,
						price: defaultPrice || min
					});
				}
				return allServices;
			}, []);
	}

	getTotalAndDepositPrices() {
		return totalAppointmentPrice(this.state.onlinePaymentServices, false);
	}

	handleError({ appointment }, error) {
		switch (error) {
			case UNAVAILABLE_SEQUENCE_ERROR:
			case CANCELLATION_ERROR:
			case INVALID_START_ERROR: {
				return {
					appointment: {
						...appointment,
						date: null
					}
				};
			}
			case UNSKILLED_CALENDAR_ERROR:
			case STRIPE_CARD_ERROR:
				return {
					errorIsOnlinePayment: true
				};
			case UNKNOWN_CALENDAR_ERROR: {
				const { steps } = appointment;
				const newSteps = steps.reduce((all, step) => {
					if (step.calendarId && step.calendarId !== 'ANY' && !step.sequence) {
						const service = businessService(
							this.props.business,
							step.serviceId
						);
						const skilledCalendars = calendarsFromFormula(service.formula);
						if (skilledCalendars.includes(step.calendarId)) {
							all.push(step);
						} else {
							all.push({
								...step,
								calendarId: null
							});
						}
					} else if (step.sequence) {
						const sequence = sequence.reduce((newSequence, subStep) => {
							if (subStep.calendarId && subStep.calendarId !== 'ANY') {
								const service = businessService(
									this.props.business,
									subStep.serviceId
								);
								const skilledCalendars = calendarsFromFormula(service.formula);
								if (skilledCalendars.includes(subStep.calendarId)) {
									newSequence.push(subStep);
								} else {
									newSequence.push({
										...subStep,
										calendarId: null
									});
								}
							} else {
								newSequence.push(subStep);
							}
							return newSequence;
						}, []);
						all.push({
							...step,
							sequence
						});
					} else {
						all.push(step);
					}
					return all;
				}, []);
				return {
					appointment: {
						...appointment,
						steps: newSteps,
						date: null
					}
				};
			}
			case UNKNOWN_SERVICE_ERROR: {
				const services = businessWebVisibleServicesById(this.props.business);
				const newSteps = appointment.steps.reduce((all, step) => {
					if (services.hasOwnProperty(step.serviceId)) {
						all.push(step);
					}
					return all;
				}, []);
				return {
					appointment: {
						...appointment,
						steps: newSteps,
						date: null
					}
				};
			}
			default:
				return null;
		}
	}

	cleanStaleError(error, type) {
		switch (error) {
			case INVALID_START_ERROR:
			case UNAVAILABLE_SEQUENCE_ERROR:
			case UNSKILLED_CALENDAR_ERROR:
			case UNKNOWN_CALENDAR_ERROR:
				return [
					events.USER_CHOSE_STEP_CALENDAR,
					events.USER_CHOSE_SUBSTEP_CALENDAR,
					events.USER_ADDED_STEP,
					events.USER_REMOVED_STEP,
					events.USER_CHOSE_DATE
				].includes(type)
					? null
					: error;
			default:
				return error;
		}
	}

	isProWithMissingPhone = () => {
		const { user, isPro } = this.props;
		const missingPhone = isPro && !user.phone;
		if (missingPhone) {
			this.setState({
				isPending: false,
				isBooking: false,
				error: MISSING_PHONE_NUMBER_ERROR_MESSAGE
			});
			scrollTo({ node: this.appointmentUser.current, animated: true });
		}
		return missingPhone;
	};

	handleConfirmableUser = hasConfirmableUser => {
		if (this.state.appointment.hasConfirmableUser !== hasConfirmableUser) {
			const { business } = this.props;
			this.setState(
				({ appointment }) => ({
					error: null,
					appointment: {
						...appointment,
						hasConfirmableUser
					}
				}),
				() => {
					if (
						business.modules &&
						business.modules.onlinePayment &&
						business.settings &&
						business.settings.onlinePayment
					) {
						this.setState({
							error: null,
							onlinePaymentServices: parseServices(
								this.state.appointment.steps,
								business
							)
						});
					}
				}
			);
		}
	};

	handleAppointmentPayment = ({
		paymentIntentId,
		newPaymentMethodFromElement,
		hasSameAmount,
		giftVoucherNumber,
		isBookedWithCure
	} = {}) => {
		const {
			selectedPaymentMethod: savedPaymentMethod,
			business,
			t,
			userId
		} = this.props;
		const isPrePayment = business?.settings?.onlinePayment?.prePayment;

		// distinguish between saved payment method and new payment method
		const paymentMethod = newPaymentMethodFromElement || savedPaymentMethod;

		// bank imprint: check card expiration
		if (!isPrePayment && !giftVoucherNumber && !isBookedWithCure) {
			const isCardExpirationDateAfterAppointment = compareAsc(
				endOfMonth(
					parseISO(
						`${paymentMethod.card.exp_year}-${(
							'0' + paymentMethod.card.exp_month
						).slice(-2)}-01`
					)
				),
				this.state.appointment.date
			);
			if (isCardExpirationDateAfterAppointment <= 0) {
				alert(t('onlinePayment.lateBookingError'));
				return;
			}
		}

		if (giftVoucherNumber) {
			this.book({
				userId,
				giftVoucherNumber
			});
		} else if (isBookedWithCure) {
			this.book({
				userId,
				isBookedWithCure
			});
		} else {
			this.book({
				userId,
				paymentMethod,
				isPrePayment,
				paymentIntentId, // undefined or false when not needed
				hasSameAmount
			});
		}
	};
}

function formatAppointmentForLambda(
	{ steps, date, commentToBusiness },
	business,
	isSinglePageApp,
	onlinePaymentServices = [],
	{ isIOSViewer }
) {
	let fromOrigin = {};
	if (process.env.WIDGET) {
		fromOrigin.env = 'whitelabel';
		fromOrigin.isWidget = process.env.WHITE_LABEL_WEBSITE !== true;
		fromOrigin.isSinglePageApp = isSinglePageApp;
		// Arbitrary naming. No tag means legacy white-label. v2 means "refonte" white-label
		fromOrigin.version = 'v2';
		fromOrigin.color = window.planity.primaryColor;
		fromOrigin.myAccountURL = window.planity.myAccountURL;
	}
	if (isNativeApp) {
		fromOrigin.env = isIOSViewer ? 'app-ios' : 'app-android';
	}
	const comment = commentToBusiness ? commentToBusiness : undefined;
	return {
		fromOrigin,
		start: format(date, 'yyyy-MM-dd HH:mm'),
		sequence: steps.map(step => {
			return {
				serviceId: step.serviceId,
				...onlinePaymentSettings(step.serviceId, onlinePaymentServices),
				...lambdaStepCalendarIds(step, business)
			};
		}),
		comment
	};
}

function onlinePaymentSettings(serviceId, onlinePaymentServices) {
	let onlinePaymentSettings = {};
	if (onlinePaymentServices.length > 0) {
		onlinePaymentServices.map(service => {
			if (service.id === serviceId) {
				onlinePaymentSettings = {
					cancelRate: service.cancelRate,
					lateCancellationDelay: service.lateCancellationDelay,
					noshowRate: service.noshowRate
				};
			}
		});
	}
	return onlinePaymentSettings;
}

function lambdaStepCalendarIds({ calendarId, serviceId, sequence }, business) {
	if (sequence) {
		const isEmpty = !sequence.find(
			step =>
				(!!step.calendarId && step.calendarId !== 'ANY') ||
				(!!step.sequence &&
					step.sequence.find(s => !!s.calendarId && s.calendarId !== 'ANY'))
		);

		if (isEmpty) {
			return null;
		} else {
			const service = businessService(business, serviceId);
			return {
				sequenceCalendarIds: (service.sequence || []).reduce(
					(formattedSequence, step, index) => {
						const stepService = businessService(business, step.serviceId);
						if (stepService && stepService.sequence) {
							formattedSequence.calendars.push(
								...lambdaStepCalendarIds(sequence[index], business)
									.sequenceCalendarIds
							);
							formattedSequence.index += 1;
						} else if (step.serviceId === 'PAUSE') {
							formattedSequence.calendars.push(null);
						} else {
							formattedSequence.index += 1;
							formattedSequence.calendars.push(
								lambdaCalendarIds(
									safeRead(sequence, [formattedSequence.index, 'calendarId'])
								)
							);
						}
						return formattedSequence;
					},
					{ calendars: [], index: -1 }
				).calendars
			};
		}
	} else {
		return {
			calendarIds: lambdaCalendarIds(calendarId)
		};
	}
}

function lambdaCalendarIds(calendarId) {
	return Array.isArray(calendarId)
		? [
				calendarId.reduce(
					(all, _calendar) =>
						_calendar === 'ANY' || !_calendar ? all : [...all, _calendar],
					[]
				)
		  ]
		: null;
}

function overlaps(x, y) {
	if (!x || !y) return false;
	return (
		(x.offset < y.offset && x.duration > y.offset) ||
		(x.offset >= y.offset && x.offset < y.duration)
	);
}

//for payment redirect we have to format bookAppointment payload in order to store it on firebase on lambda side
//firebase can't store undefined data and JSON.parse doesn't parse undefined data either
function unformatFirebaseResponse(data) {
	const parsedData = JSON.parse(data);
	const { vevents, sequenceId, businessId, articles } = parsedData;
	const formattedVevents = vevents.map(item => {
		return Object.entries(item).reduce((all, [currentKey, currentValue]) => {
			if (currentValue === 'undefined') {
				all[currentKey] = undefined;
			} else {
				all[currentKey] = currentValue;
			}
			return all;
		}, {});
	});

	return {
		vevents: formattedVevents,
		sequenceId,
		businessId: businessId === 'undefined' ? undefined : businessId,
		articles
	};
}

function getChildInfos(selectedUser) {
	// selectedUser is parent or null
	if (!selectedUser?.childId) return { childId: null, childName: null };

	return { childId: selectedUser.childId, childName: selectedUser.name };
}

export function reduceBookAppointment(state, type, payload, business) {
	switch (type) {
		case events.USER_ADDED_STEP: {
			return {
				...state,
				steps: [...state.steps, { serviceId: null }]
			};
		}
		case events.USER_CHOSE_PAST_APPOINTMENT: {
			let currentIndex = 0;
			const steps = [...state.steps];
			const initialSequence = payload.sequence;
			initialSequence
				.sort((x, y) => x.sort > y.sort)
				.map(step => {
					const s = {};
					const {
						selectedCalendars,
						serviceId,
						serviceOrigin,
						serviceOriginIndex
					} = step;
					s.serviceId = serviceOrigin || serviceId;
					const service = businessService(business, serviceOrigin || serviceId);
					const serviceStartsWithPause =
						service &&
						service.sequence &&
						service.sequence[0].serviceId === 'PAUSE';

					if (
						service &&
						(!serviceOriginIndex ||
							serviceOriginIndex === (serviceStartsWithPause ? 2 : 1))
					) {
						const isComplex = !!service.sequence;

						const hasSubsteps =
							isComplex && canChooseServiceSubsteps(service, business);

						if (hasSubsteps) {
							s.sequence = (service.sequence || []).reduce(
								(sequence, sequenceStep) => {
									if (sequenceStep.serviceId !== 'PAUSE') {
										const matchingStep = initialSequence.find(
											s => s.sort === currentIndex
										);

										const fullService = businessService(
											business,
											sequenceStep.serviceId
										);
										const [numberOfRequiredCalendar] = parseFormula(
											fullService.formula,
											business.calendars
										);

										// find matching step in initial sequence to get selected calendar

										const subStep = { serviceId: sequenceStep.serviceId };

										// if there is only one selectable calendar, preselect it
										const selectableCalendars =
											businessServiceWebSelectableCalendars(
												business,
												subStep.serviceId
											);

										// if past app selected calendar can be chosen, choose it
										if (
											matchingStep &&
											matchingStep.selectedCalendars &&
											selectableCalendars &&
											selectableCalendars.length > 1
										) {
											// récuperer le calendrier qui correspond au calendrier humain dans calendars
											const selectedCalendar = (
												matchingStep.selectedCalendars || ''
											)
												.split(',')
												.filter(
													cal =>
														!!selectableCalendars.find(
															calendar => calendar.id === cal
														)
												);
											subStep.calendarId = new Array(numberOfRequiredCalendar)
												.fill(null)
												.map((_, i) => selectedCalendar[i] || null);
										} else if (
											selectableCalendars &&
											selectableCalendars.length === 1 &&
											numberOfRequiredCalendar === 1
										) {
											subStep.calendarId = [
												safeRead(selectableCalendars, [0, 'id'])
											];
										} else {
											subStep.calendarId = new Array(
												numberOfRequiredCalendar
											).fill(null);
										}
										sequence.push(subStep);
										currentIndex += 1;
									}
									return sequence;
								},
								[]
							);
						} else {
							currentIndex += 1;
							// if there is only one selectable calendar, preselect it
							const selectableCalendars = businessServiceWebSelectableCalendars(
								business,
								serviceId
							);
							const serviceHasAtLeastOneSequence =
								service?.sequence?.length > 0;

							const fullService = businessService(
								business,
								serviceHasAtLeastOneSequence
									? service?.sequence?.[0]?.serviceId
									: serviceId
							);
							const [numberOfRequiredCalendar] = parseFormula(
								fullService.formula,
								business.calendars
							);

							// if past app selected calendar can be chosen, choose it
							if (
								selectedCalendars &&
								!isComplex &&
								selectableCalendars &&
								selectableCalendars.length > 1
							) {
								// if there are several selectedCalendars (dont know if that can really happen... find the first calendar that is selectable)
								const selectedCalendar = (selectedCalendars || '')
									.split(',')
									.filter(
										cal =>
											!!selectableCalendars.find(
												calendar => calendar.id === cal
											)
									);
								s.calendarId = new Array(numberOfRequiredCalendar)
									.fill(null)
									.map((_, i) => selectedCalendar[i] || null);
							} else if (
								selectableCalendars &&
								selectableCalendars.length === 1 &&
								numberOfRequiredCalendar === 1
							) {
								s.calendarId = [safeRead(selectableCalendars, [0, 'id'])];
							} else {
								s.calendarId = new Array(numberOfRequiredCalendar).fill(null);
							}
						}

						if (steps[0].serviceId !== null) {
							steps.push(s);
						} else {
							steps[0] = s;
						}
					}
				});

			return {
				...state,
				steps
			};
		}
		case events.USER_CHOSE_STEP_SERVICE: {
			// CAS OU LE USER CHOISI UNE PRESTATION, PERMET LA CREATION DES CALENDARID IN SUBSTEPS
			const steps = [...state.steps];
			const stepIndex = steps.length - 1;
			const step = { ...steps[stepIndex] };
			step.serviceId = payload.serviceId;

			// if the service is complex and has selectable subSteps, add sequence prop
			const populateSequence = (step, serviceId) => {
				const service = businessService(business, serviceId);
				step.synchronous = service.synchronous || false;
				const isComplex = !!service.sequence;
				const hasSubsteps =
					isComplex && canChooseServiceSubsteps(service, business);
				if (hasSubsteps) {
					step.sequence = (service.sequence || []).reduce(
						(sequence, sequenceStep) => {
							if (sequenceStep.serviceId !== 'PAUSE') {
								const fullService = businessService(
									business,
									sequenceStep.serviceId
								);
								const [numberOfRequiredCalendar] = parseFormula(
									fullService.formula,
									business.calendars
								);
								const subStep = {
									serviceId: sequenceStep.serviceId,
									offset: sequenceStep.offset || 0,
									synchronous: sequenceStep.synchronous || false,
									calendarId: new Array(numberOfRequiredCalendar).fill(null),
									duration: fullService.duration
								};
								// Recursive to handle synchronous appointments that can have complex sub-steps
								populateSequence(subStep, sequenceStep.serviceId);
								// if there is only one selectable calendar, preselect it
								const selectableCalendars =
									businessServiceWebSelectableCalendars(
										business,
										subStep.serviceId
									);
								if (
									selectableCalendars &&
									selectableCalendars.length === 1 &&
									numberOfRequiredCalendar === 1
								) {
									const availableCalendar = safeRead(selectableCalendars, [
										0,
										'id'
									]);
									subStep.calendarId = [availableCalendar];
								}
								sequence.push(subStep);
							}
							return sequence;
						},
						[]
					);
				} else {
					const fullService = businessService(business, step.serviceId);
					const [numberOfRequiredCalendar] = parseFormula(
						fullService.formula,
						business.calendars
					);

					step.calendarId = new Array(numberOfRequiredCalendar || 1).fill(null);
				}
				// if there is only one selectable calendar, preselect it
				const selectableCalendars = businessServiceWebSelectableCalendars(
					business,
					payload.serviceId
				);
				if (selectableCalendars && selectableCalendars.length === 1) {
					const availableCalendar = safeRead(selectableCalendars, [0, 'id']);
					step.calendarId = [availableCalendar];
				}
			};

			populateSequence(step, payload.serviceId);

			steps[stepIndex] = step;
			return {
				...state,
				steps
			};
		}
		case events.USER_CHOSE_STEP_CALENDAR_NEW: {
			const { calendarId, path, synchronous, calendarPosition } = payload;
			const _nextState = produce(state, newState => {
				const allSteps = newState.steps;
				const [stepIndex, subStepIndex] = path;
				const step = allSteps[stepIndex];
				if (typeof subStepIndex !== 'undefined') {
					step.sequence.forEach((_, index) => {
						const selectableCalendars = (
							businessServiceWebSelectableCalendars(
								business,
								step.sequence[index].serviceId
							) || []
						).map(({ id }) => id);
						const currentCalendarRef = step.sequence[index].calendarId;
						if (subStepIndex === index) {
							if (currentCalendarRef.indexOf(calendarId) === -1) {
								// if calendar is not in calendarId, add it at the desire position
								currentCalendarRef[calendarPosition] = calendarId;
								if (calendarPosition !== 0) {
									// add ANY to other position before it to avoid no avaibalities
									for (let i = calendarPosition; i > -1; i--) {
										if (!currentCalendarRef[i]) {
											currentCalendarRef[i] = 'ANY';
										}
									}
								}
							}
						} else if (currentCalendarRef.filter(x => !!x).length === 0) {
							// Check if other step are empty / Case of the first selection;
							if (
								(!overlaps(step.sequence[subStepIndex], step.sequence[index]) ||
									!synchronous) &&
								(selectableCalendars || []).includes(calendarId)
							) {
								currentCalendarRef[0] = calendarId;
							}
						}
					});
				} else {
					if (Array.isArray(step.calendarId)) {
						if (step.calendarId.indexOf(calendarId) === -1) {
							step.calendarId[calendarPosition] = calendarId;
							if (calendarPosition !== 0) {
								// add ANY to other position before it to avoid no avaibalities
								for (let i = calendarPosition; i > -1; i--) {
									if (!step.calendarId[i]) {
										step.calendarId[i] = 'ANY';
									}
								}
							}
						}
					} else {
						step.calendarId = [calendarId];
					}
				}
				return newState;
			});
			return _nextState;
		}
		case events.USER_REMOVED_STEP: {
			const steps = [...state.steps];
			steps.splice(payload.stepIndex, 1);
			if (!steps.length) {
				steps.push({ serviceId: null });
			}
			return {
				...state,
				date: null,
				steps
			};
		}
		case events.USER_CHOSE_DATE: {
			return {
				...state,
				date: payload.date
			};
		}
		case events.USER_REMOVED_DATE: {
			return {
				...state,
				date: null
			};
		}
		case events.USER_ADD_COMMENT: {
			return {
				...state,
				commentToBusiness: payload.commentToBusiness
			};
		}
		default:
			return state;
	}
}

const styles = {
	bookAppointment: {
		marginRight: '0 !important',
		width: '100%',
		maxWidth: 976
	},
	confirmation: {
		marginTop: 32
	},
	divToMakeItBeautiful: {
		height: 120,
		[breakpoints.desktopQuery]: {
			height: 150
		}
	}
};

export default withGoalEvents(
	withModal(
		withTheme(
			withViewerHeaders(
				withStripeFees(
					withFormFactor(
						withAppBanner(
							withTranslation()(
								withStripeElementsConsumer(
									withRouter(OnlinePaymentConsumer(BookAppointmentComponent))
								)
							)
						)
					)
				)
			)
		)
	)
);
